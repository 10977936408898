body {
  font-family: 'Horas';
}

@font-face {
  font-family: 'Horas';
  src: url('Horas-Medium.ttf') format('ttf'), url('Horas-Medium.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Horas';
  src: url('Horas-Bold.ttf') format('ttf'), url('Horas-Bold.ttf') format('woff');
    font-weight: 600;
  font-style: normal;
  font-display: swap;
}