.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* old style */

/* .mantine-Select-input {
  height: 1.35rem !important;
  min-height: 1.35rem !important;
}

.mantine-TextInput-input {
  height: 1.35rem !important;
  min-height: 1.35rem !important;
}

.mantine-MultiSelect-input {
  min-height: 1.35rem !important;
}

.mantine-MultiSelect-values {}

.mantine-PasswordInput-innerInput {
  height: 1.35rem !important;
  min-height: 1.35rem !important;
  line-height: calc(1.35rem - 0.25rem);
  border-radius: 0.25rem !important;
}

.mantine-Input-rightSection {
  height: 1.35rem !important;
  min-height: 1.35rem !important;
}

.mantine-PasswordInput-input {
  height: 1.35rem !important;
  min-height: 1.35rem !important;
}

.mantine-DatePickerInput-input {
  height: 1.35rem !important;
  min-height: 1.35rem !important;
}

.mantine-Textarea-input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
} */
/* old */
.custom-text-center .mantine-Text-root {
  text-align: center;
}
.custom-item-right .mantine-Grid-root  {
  justify-content: flex-end!important
}

.custom-margin  {
  margin: 15px !important;
}

.custom-btn-title .mantine-Button-root{
  border-radius: 0.25rem 0.25rem 0 0;
}
.custom-text-right .mantine-Grid-col{
  text-align: right;
}
.mantine-NumberInput-rightSection{
  font-size: 10px;
  margin: 5px;
 
}
.custom-font{
  font-size: 12px!important;
  vertical-align: middle;
}

.custom-width-btn .mantine-Button-root{
  min-width: 3rem!important;
}

input:disabled+.mantine-Switch-track {
    background-color: #228be6;
    border-color: #228be6;
}

.notable-height {
  min-height: 1vh !important;
}

.custom-col .mantine-Grid-col{
    background-color: #F2F2F2;
    border-radius: 10px 10px 10px 10px;
    text-align: center;
    border: 1px solid #CFCFCF;
    font-size: 0.60rem;
    border-radius: 5px;
    opacity: 1;
    margin:2px;
    cursor: pointer;
}

.tab-width .mantine-Tabs-tab{
  width:110%;
  font-size: 0.65rem;
  padding: 5px;
 line-height: 1rem;
}

.selected-card {
  color: #1C7ED6; 
}
.selected-card:hover {
  background-color: #e0e0e0; 
  cursor: pointer; 
}

.customborder .mantine-Button-root{
  border-radius:0!important;
}
.customborderleft {
  border-top-left-radius: 0.25rem!important;
}
.blue-color{
  color:#1C7ED6;
  font-weight:bold
}
.qtycard{
  display: inline-block;
  width: "30px";
  text-align: "center",
}
.custom-btn .mantine-Button-root{
      min-width: 0rem!important;
     /* width: 25%; */
}
.rdt_TableRow{
max-height: max-content!important;
}